.desktop-instructions-content {
    margin-bottom: 0%;
}

.desktop-instructions {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Ensure there are no default margins */
    height: 100vh;
    /* Use the full viewport height */
    background: linear-gradient(to bottom, var(--top-color) 0%, var(--top-color) 35%, var(--bottom-color) 35%, var(--bottom-color) 100%);
    /*background: linear-gradient(to bottom, #6899dd 0%, #6899dd 35%, #c7ddfc 35%, #c7ddfc 100%);
    background: linear-gradient(to bottom, #a8ee8c 0%, #a8ee8c 35%, #daffcb 35%, #daffcb 100%);
    linear-gradient(to bottom, #e8d470 0%, #e8d470 35%, #faf0c0 35%, #faf0c0 100%)*/
    transition: opacity 0.3s ease;
}

.desktop-instructions-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 35% 65%;
    margin: 0vw 4vw;
}

.desktop-instructions-logo-column {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
}

.desktop-instructions-title {
    display: flex;
    font-size: 2.5vw;
    background-color: #5d1900;
    color: #fff;
    align-content: center;
    justify-content: center;
    padding: 1vw ;
    border: 0.2vw solid #000000;
}

.desktop-instructions-logo-img {
    width: auto;
    height: 6vw;
    padding-left: 1.25vw;
}

.desktop-instructions-top-row {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
}

.desktop-instructions-

.desktop-instructions-content-row {
    display: grid;
    grid-template-rows: 35% 65%;
}

.desktop-instructions-container {
    display: grid;
    position: relative;
    grid-template-rows: 100%;
    height: 63vh;
}

.desktop-instructions-top-section {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    height: 35vh;
    /* Adjust as needed */
    width: 100%;
    /* Ensure the parent container takes the full width */
    overflow: hidden;
    /* Optional: hide any overflow if needed */
}

.desktop-instructions-top-section-deco {
    bottom: 0;
    position: relative;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: end;
}

.desktop-instructions-deco-img {
    width: 100%;
    height: auto;
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffff75 0%, #ffffff00 100%);
}

.desktop-instructions-deco-img-opposite {
    width: 100%;
    height: auto;
    transform: scaleX(-1);
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffff75 0%, #ffffff00 100%);
}

.desktop-instructions-competition-title-section {
    align-items: center;
    justify-content: center;
    top: 0;
    position: relative;
    display: inline-block;
}

.desktop-instructions-competition-title {
    font-size: 2vw;
    border-radius: 0.5vw;
    font-weight: 800;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.desktop-instructions-competition-title-img {
    height: auto;
    width: auto;
    max-height: 35vh;
    max-width: 100%;
    display: block;
}

.desktop-instructions-competition-name-overlay-text {
    position: absolute;
    top: 52%;
    /* Adjust this value to position the text vertically */
    left: 50%;
    /* Adjust this value to position the text horizontally */
    transform: translate(-50%, -50%);
    color: rgb(0, 0, 0);
    /* Adjust text color */
    padding: 5px;
    /* Optional: padding for better readability */
    font-size: 2.75vw;
    /* Adjust font size */
    text-wrap: nowrap;
    font-weight: 900;
    letter-spacing: 0.025vw;
}

.desktop-instructions-competition-title-top {
    display: flex;
    justify-content: center;
    height: 5vw;
    text-align: center;
    align-items: center;
}

.desktop-instructions-competition-title-bottom {
    display: flex;
    height: 5vw;
    color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.desktop-instructions-bottom-container {
    max-height: 62vh;
    margin-top: 0vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: top;
    padding-top: 1vw;
    height: 100%;
    width: 100%;
    position: relative;
    border-top: 0;
    border-bottom: 0;
    top: 0;
    opacity: 1;
    background-color: #ffffff;
    border-top: 0.2vw solid black;
}

.desktop-instructions-bottom-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.desktop-instructions-item {
    display: grid;
    align-items: center;
    justify-content: left;
    padding: 1vw;
    grid-template-columns: 3% 97%;
}

.desktop-instruction-text {
    padding-left: 1vw;
    font-size: 1.25vw;
    font-weight: 400;
}