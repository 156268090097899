.desktop-awards-content {
    margin-top: 2%;
    margin-bottom: 5%;
}

.desktop-awards-title {
    padding-bottom: 0vw;
}

.awards-container {
    display: grid;
    box-sizing: border-box;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 4vw;
    margin: 2%;
}

.non-state-topper-padding {
    margin-top: 5vw;
}

.awards-category {
    display: flex;
    flex-direction: column;
    border: 0.2vw solid black;
    border-radius: 0.5vw;
    background-color: #ffffff;
}

#ParticipantAwards {
    background-color: #d1d1d1;
}

#StateAwards {
    background-color: #ffc021;
}

#ClassAwards {
    background-color: #92e8c6;
    /*7abbd7*/
}

.awards-category-title {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    border-radius: 0.25vw;
}

.state-topper-category-title {
    grid-template-columns: 2fr 3fr;
}

.awards-category-title-text {
    display: flex;
    padding: 1vw;
    font-size: 2vw;
    font-weight: 600;
    color: #000000;
}

.awards-category-title-image {
    display: flex;
    width: 100%;
    align-items: center;
    /* To vertically center the image if needed */
    justify-content: center;
    /* To horizontally center the image if needed */
    overflow: hidden;
    /* To ensure nothing spills out of the container */
}

.non-state-topper-image {
    padding: 1vw 0vw;
}

.state-topper-image {
    padding: 1vw 0vw;
}

.awards-category-title-image img {
    width: 100%;
    height: auto;
    max-width: 100%;
    /* Ensure it doesn't grow beyond its container */
}

.awards-category-text {
    display: flex;
    border-radius: 0.25vw;
    border-top-right-radius: 0%;
    border-top-left-radius: 0%;
    background-color: #ffffff;
    padding: 2vw;
    flex-direction: column;
}

.awards-category-text-main {
    text-align: center;
    align-items: center;
    justify-content: center;
    border-radius: 0.5vw;
    padding: 1vw;
    font-weight: 700;
    font-size: 1.5vw;
}

.awards-category-text-main.participation {
    background-color: #ededed;
}

.awards-category-text-main.state {
    background-color: #ededed;
    margin: 2vw;
    margin-top: 0;
}

.awards-category-text-main.class {
    background-color: #ededed;
}

.awards-category-text-main.class.gold {
    background-color: #fae88d;
}

.awards-category-text-normal {
    text-align: center;
    align-items: center;
    justify-content: center;
    font-size: 1.35vw;
    padding: 0.5vw 0
}

.awards-scholarship-image {
    position: relative;
    width: 100%;
    height: auto;
    max-width: 100%;
}

.awards-scholarship-text {
    margin: 0;
    padding: 0;
    padding-top: 0.25vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 2.5vw;
    font-weight: 700;
    color: #ffea00;
    line-height: 4.85vw;
    letter-spacing: 0.1vw;
    -webkit-text-stroke: 0.05vw #dcca70;
}

.awards-guidelines-text {
    display: flex;
    justify-content: center;
    background-color: #fae6e6;
    margin: 1vw 2vw;
    padding: 1vw;
    border-radius: 0.5vw;
    font-size: 1.25vw;
}