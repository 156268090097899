@-webkit-keyframes bounce {
    0% {
        -webkit-transform: scale(1, 1);
    }

    10% {
        -webkit-transform: scale(0.98, 0.98);
    }

    20% {
        -webkit-transform: scale(0.96, 0.96);
    }

    30% {
        -webkit-transform: scale(0.94, 0.94);
    }

    40% {
        -webkit-transform: scale(0.92, 0.92);
    }

    50% {
        -webkit-transform: scale(0.9, 0.9);
    }

    60% {
        -webkit-transform: scale(0.92, 0.92);
    }

    70% {
        -webkit-transform: scale(0.94, 0.94);
    }

    80% {
        -webkit-transform: scale(0.96, 0.96);
    }

    90% {
        -webkit-transform: scale(0.98, 0.98);
    }

    100% {
        -webkit-transform: scale(1.0, 1.0);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1, 1);
    }

    10% {
        transform: scale(0.98, 0.98);
    }

    20% {
        transform: scale(0.96, 0.96);
    }

    30% {
        transform: scale(0.94, 0.94);
    }

    40% {
        transform: scale(0.92, 0.92);
    }

    50% {
        transform: scale(0.9, 0.9);
    }

    60% {
        transform: scale(0.92, 0.92);
    }

    70% {
        transform: scale(0.94, 0.94);
    }

    80% {
        transform: scale(0.96, 0.96);
    }

    90% {
        transform: scale(0.98, 0.98);
    }

    100% {
        transform: scale(1.0, 1.0);
    }
}


.bounce {
    -webkit-animation-duration: 200ms;
    animation-duration: 200ms;
    -webkit-animation-name: bounce;
    animation-name: bounce;
}