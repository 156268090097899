.awards {
    display: flex;
    box-sizing: border-box;
    width: 100%;
    background-size: contain;
    background-repeat: repeat;
    background-position: left;
}

.awards-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 5%;
    width: 90%;
}

.awards-title {
    text-align: center;
    color: #000000;/*26008c*/
    font-weight: 700;
}

.awards-title-content {
    font-size: 3.5vw;
    font-weight: 600;
    margin: 0 20vw;
    padding: 1.5vw;
    background-color: #f3d134;/*8caeff, ffdd00*/
    box-shadow: 0.75vw 0.75vw;
}

.mobile-awards-title-content {
    font-size: 3.5vh;
    font-weight: 600;
    margin: 0 2vh;
    padding: 1.5vh;
    background-color: #f3d134;/*8caeff, ffdd00*/
    box-shadow: 1vh 1vh;
}

.awards-container-mobile {
    display: block;
    box-sizing: border-box;
    margin-top: 7%;
}

.mobile-awards-content {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    margin: 10%;
    margin-top: 0%;
    width: 90%;
}