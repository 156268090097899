.tnc-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: #ffffff;
    margin-bottom: 0;
}

.tnc-content-container {
    flex: 1;
    display: flex;
    overflow: hidden;
    background-color: #ffffff;
}

.tnc-contents {
    overflow: overlay;
    scroll-behavior: smooth;
    text-align: justify;
    pointer-events: none;
}

.tnc-contents::-webkit-scrollbar {
    width: 4px;
}

.tnc-contents::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0.15vw solid rgb(194, 194, 194);
    background-color: #ff8800;
}

.big-heading-text {
    font-weight: 700;
}

.heading-text {
    font-weight: 700;
}

.small-heading-text {
    font-weight: 700;
}

.normal-text {
    font-weight: 400;
}

.bold-text {
    font-weight: 600;
}

.bold-height-text {
    font-weight: 600;
}

.tnc-divider-line {
    align-self: center;
    background-color: rgba(139, 139, 139, 0.9);
}