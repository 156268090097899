.desktop-guidelines-content {
    margin-bottom: 2%;
}

.desktop-guidelines-title {
    padding-bottom: 0vw;
    text-decoration: underline;
}

.guidelines-number-circle-outside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.guidelines-number-circle {
    border-radius: 50%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5vw;
    height: 2.5vw;
    margin-left: 0.5vw;
}

.guidelines-number {
    font-size: 1.5vw;
    color: #ffffff;
}

.guidelines-heading {
    font-size: 1.15vw;
    font-weight: 600;
    color: #000000;
    display: grid;
    grid-template-columns: 7.5% 90%;
    column-gap: 2.5%;
}

.guidelines-heading-text {
    font-size: 1.75vw;
    font-weight: 800;
    display: flex;
    text-align: justify;
    align-items: center;
    color: #000000;
    text-decoration: underline;
}

.guidelines-subheading-text {
    width: 80%;
    margin: 0 auto;
    font-size: 1.5vw;
    font-weight: 800;
    display: flex;
    text-align: justify;
    align-items: center;
    color: #000000;
}

.guidelines-box {
    font-size: 1.25vw;
    font-weight: 500;
    display: flex;
    text-align: justify;
    color: #000000;
    background-color: #f1f1f1;
    flex-direction: column;
    border-bottom: 0.1vw solid #000000;
    padding: 1.25vw;
    margin: 1.25vw 0vw;
}

.guidelines-detail {
    width: 80%;
    margin: 0 auto;
    font-weight: 400;
    padding: 1vw 0vw;
}

.guidelines-example {
    font-style: italic;
    padding-top: 0;
    font-weight: 500;
}

.table-container {
    padding: 2vw 0vw;
    padding-bottom: 1vw;
    width: 80%;
    margin: 0 auto;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
}

.custom-table td {
    border: 0.1vw solid black;
    padding: 0.65vw;
    font-size: 1.25vw;
    font-weight: 600;
}

.custom-table tr:nth-child(odd) {
    background-color: #ecf3fb;
}

.custom-table tr:nth-child(even) {
    background-color: #fbefef;
}

.custom-table td:nth-child(1) {
    width: 30%;
}

.custom-table td:nth-child(2) {
    width: 70%;
}