.desktop-themes-content {
    margin-bottom: 0%;
}

.desktop-themes-title {
    padding-bottom: 5vw;
}

.desktop-themes-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* Ensure there are no default margins */
    height: 100vh;
    /* Use the full viewport height */
    background: linear-gradient(to bottom, var(--top-color) 0%, var(--top-color) 35%, var(--bottom-color) 35%, var(--bottom-color) 100%);
    /*background: linear-gradient(to bottom, #6899dd 0%, #6899dd 35%, #c7ddfc 35%, #c7ddfc 100%);
    background: linear-gradient(to bottom, #a8ee8c 0%, #a8ee8c 35%, #daffcb 35%, #daffcb 100%);
    linear-gradient(to bottom, #e8d470 0%, #e8d470 35%, #faf0c0 35%, #faf0c0 100%)*/
    transition: opacity 0.3s ease;
}

.desktop-themes-modal-content {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1vw;
    margin: 0vw 4vw;
}

.desktop-modal-logo {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.desktop-modal-logo-img {
    width: auto;
    height: 6vw;
    padding-left: 1.25vw;
}

.modal-top-row {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 17.5% 82.5%;
}

.modal-content-row {
    display: grid;
    grid-template-rows: 35% 65%;
}

.modal-classes-list {
    background-color: #fcb57b;
    display: grid;
    max-height: 82.5vh;
    margin-right: 1vw;
    padding-right: 2.5vw;
    padding-top: 1.25vw;
    margin-left: 1.5vw;
    overflow-y: scroll;
    border-top-left-radius: 0.5vw;
    border-top-right-radius: 0.5vw;
}

.modal-classes-list::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.15vw solid rgb(194, 194, 194);
    background-color: #ffffff;
}

.modal-classes-list::-webkit-scrollbar-track {
    background-color: #000000;
}

.class-container {
    align-items: center;
    margin-bottom: 1vw;
    margin-left: 1vw;
    /* spacing between class boxes */
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    /* Added border to the transition */
    aspect-ratio: 16/4;
    border-radius: 0.5vw;
    display: grid;
    grid-template-rows: 1fr 0fr;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    background-color: #241300;
    border: 0.2vw solid white;
    /* Added a transparent border so that it doesn't affect layout when it changes color */
    width: 22.5vw;
}

.class-container:hover {
    background-color: #ffecbe;
}

.class-container.expanded {
    grid-template-rows: 1fr 1fr;
    transition: all 0.3s ease-in-out 0.3s, border 0.3s;
    /* Added a delay and also transition for border */
    aspect-ratio: 1/1;
    background-color: #ffffff;
    animation: tempBorder 0.3s forwards;
    /* Added an animation */
    cursor: default;
}

.class-container.scroll-triggered {
    animation: tempBorder 0.75s forwards;
    transition-delay: 0.75s;
    /* Longer delay when scrolled */
}

@keyframes tempBorder {

    0%,
    100% {
        border-color: transparent;
    }

    1% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }

    99% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }
}

.class-container-dummy {
    cursor: default;
    aspect-ratio: 16/4;
    box-sizing: border-box;
    background-color: transparent;
    margin-bottom: 2vw;
}

.class-image {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expanded .class-image {
    height: 15vw;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.3s;
    /* Added delay for the height transition */
}

.scroll-triggered .class-image {
    height: 15vw;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.class-image img {
    height: 15vw;
    width: auto;

}

.class-text {
    display: grid;
    grid-template-columns: 35% 65%;
}

.KG-class-text {
    display: grid;
    grid-template-columns: 100% 0%;
}

.expanded .class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.3s;
}

.expanded .KG-class-text {
    display: grid;
    grid-template-columns: 100% 0%;
    transition: all 0.3s ease-in-out 0.3s;
}

.scroll-triggered .class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.75s;
}

.scroll-triggered .KG-class-text {
    display: grid;
    grid-template-columns: 100% 0%;
    transition: all 0.3s ease-in-out 0.75s;
}

.class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 2vw;
    font-weight: 600;
    color: #ffffff;
}

.class-container:hover .class-title {
    color: #000000;
}

.class-title-class-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 2vw;
    font-weight: 600;
    color: #ffffff;
}

.class-container:hover .class-title-class-number {
    color: #000000;
}

.KG-class-text .class-title-class-number {
    margin: 0 1vw;
}

.expanded .class-title-class-number {
    justify-content: center;
    font-size: 6vw;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.3s;
    /* Added delay for the height transition */
}

.expanded .class-title {
    color: #000000;
}

.expanded .KG-class-text .class-title-class-number {
    font-size: 4.5vw;
}

.scroll-triggered .class-title-class-number {
    justify-content: center;
    font-size: 6vw;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.scroll-triggered .KG-class-text .class-title-class-number {
    font-size: 4.5vw;
}

.modal-syllabus-container {
    display: grid;
    position: relative;
    grid-template-rows: 100%;
    height: 63vh;
}

.modal-syllabus-top-section {
    display: grid;
    grid-template-columns: 15% 70% 15%;
    height: 35vh;
    /* Adjust as needed */
    width: 100%;
    /* Ensure the parent container takes the full width */
    overflow: hidden;
    /* Optional: hide any overflow if needed */
}

.modal-syllabus-top-section-deco {
    bottom: 0;
    position: relative;
    height: 35vh;
    display: flex;
    justify-content: center;
    align-items: end;
}

.modal-deco-img {
    width: 100%;
    height: auto;
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffff75 0%, #ffffff00 100%);
}

.modal-deco-img-opposite {
    width: 100%;
    height: auto;
    transform: scaleX(-1);
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffff75 0%, #ffffff00 100%);
}

.modal-competition-title-section {
    align-items: center;
    justify-content: center;
    top: 0;
    position: relative;
    display: inline-block;
}

.modal-competition-title {
    font-size: 2vw;
    border-radius: 0.5vw;
    font-weight: 800;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-competition-title-img {
    height: auto;
    width: auto;
    max-height: 35vh;
    max-width: 100%;
    display: block;
}

.competition-name-overlay-text {
    position: absolute;
    top: 52%;
    /* Adjust this value to position the text vertically */
    left: 50%;
    /* Adjust this value to position the text horizontally */
    transform: translate(-50%, -50%);
    color: rgb(0, 0, 0);
    /* Adjust text color */
    padding: 5px;
    /* Optional: padding for better readability */
    font-size: 2.75vw;
    /* Adjust font size */
    text-wrap: nowrap;
    font-weight: 900;
    letter-spacing: 0.025vw;
}

.modal-competition-title-top {
    display: flex;
    justify-content: center;
    height: 5vw;
    text-align: center;
    align-items: center;
}

.modal-competition-title-bottom {
    display: flex;
    height: 5vw;
    color: #fff;
    justify-content: center;
    text-align: center;
    align-items: center;
}

.modal-syllabus-bottom-container {
    max-height: 62vh;
    margin-top: 0vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: top;
    padding-top: 1vw;
    height: 100%;
    width: 100%;
    position: relative;
    border-top: 0;
    border-bottom: 0;
    top: 0;
    opacity: 0;
    background-color: #ffffff;
    border-top: 0.2vw solid black;
}

.modal-syllabus-bottom-container::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.initial-opacity.modal-syllabus-bottom-container {
    opacity: 1;
}

.full-opacity.modal-syllabus-bottom-container {
    opacity: 1;
    transition: opacity 0.8s ease;
}

.modal-competition-themes {
    width: 95%;
}

.modal-competition-themes-initial-text {
    font-size: 1.95vw;
    padding-bottom: 1vw;
    padding-left: 1vw;
    font-weight: 500;
    text-align: left;
    color: #000000;
    text-decoration: none;
}

.modal-competition-drawing-syllabus-item {
    border-radius: 0.25vw;
    padding: 0.5vw 1vw;
    margin-bottom: 1vw;
    font-size: 1.35vw;
    font-weight: 500;
    text-align: left;
    color: #000000;
    display: grid;
    grid-template-columns: 5% 4% 20% 4% 68%;
}

.modal-competition-drawing-syllabus-container-divider {
    display: flex;
    align-items: center;
    justify-content: center;

}

.modal-competition-syllabus-number-circle {
    height: 2.5vw;
    width: 2.5vw;
    border-radius: 2.5vw;
    font-size: 1.5vw;
    color: #000000;
    background-color: #ffe100;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.modal-competition-drawing-syllabus-type {
    background-color: #ffa9a9;
    border-radius: 0.25vw;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 0.5vw;
    font-weight: 700;
    max-height: 5vh;
}

.modal-competition-drawing-syllabus-description {
    text-align: left;
    margin: 1vw;
}


.modal-competition-colouring-syllabus-container {
    padding: 0.5vw 1vw;
    font-size: 1.25vw;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.modal-competition-colouring-syllabus-item {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
}

.modal-competition-colouring-syllabus-item-info {
    display: grid;
    grid-template-columns: 12.5% 80% 7.5%;
}

.modal-competition-colouring-syllabus-description {
    display: flex;
    align-items: center;
    align-self: center;
}

.modal-competition-colouring-image {
    margin: 1vw 0;
    margin-left: 3vw;
    width: 24vw;
    height: 13.715vw;
    border: 0.15vw solid #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vw;
}

.modal-display-colouring-img {
    border-radius: 1vw;
    width: 100%;
    height: auto;
}

.modal-competition-colouring-image-download-link {
    margin: 0 7.5vw;
    font-size: 1.25vw;
    padding: 0.75vw;
    border-radius: 1.5vw;
    border: 0.3vw double white;
    color: #fff;
    background-image: linear-gradient( to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.modal-competition-colouring-image-download-link:hover {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #ffffff 0%, #ffffff 60%, #ffffff 100%);
}

.modal-competition-colouring-image-download-link:active {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #dedede 0%, #dedede 60%, #dedede 100%);
}

.modal-competition-colouring-image-download-link-text {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    text-align: center;
}

.modal-competition-colouring-image-download-link-arrow {    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.download-image-link-arrow-img {
    display: flex;
    justify-self: center;
    align-self: center;
    height: auto;
    width: 2vw;
    transform: rotate(90deg);
}



.modal-competition-handwriting-syllabus-container {
    padding: 0.5vw 1vw;
    font-size: 1.25vw;
    font-weight: 500;
    display: grid;
    grid-template-columns: 17.5vw auto;
    column-gap: 5vw;
}

.modal-competition-handwriting-syllabus-sheet-left {
    display: grid;
    grid-template-rows: 1fr auto 1fr;
}

.modal-competition-handwriting-syllabus-sheet-info {
    display: grid;
    grid-template-columns: 12.5% 80% 7.5%;
}

.modal-competition-handwriting-syllabus-description {
    display: flex;
    align-items: center;
    align-self: center;
}

.modal-competition-handwriting-sheet {
    margin: 1vw 0;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 20vw;
}

.modal-display-handwriting-img {
    border-radius: 1vw;
    border: 0.15vw solid #dbdbdb;
    height: auto;
    padding: 0.35vw;
    width: 10vw;
}

.modal-competition-handwriting-sheet-download-link {
    margin: 0 1vw;
    font-size: 1.15vw;
    padding: 0.75vw;
    border-radius: 1.5vw;
    border: 0.3vw double white;
    color: #fff;
    background-image: linear-gradient( to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.modal-competition-handwriting-sheet-download-link:hover {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #ffffff 0%, #ffffff 60%, #ffffff 100%);
}

.modal-competition-handwriting-sheet-download-link:active {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #dedede 0%, #dedede 60%, #dedede 100%);
}

.modal-competition-handwriting-sheet-download-link-text {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    text-align: center;
}

.modal-competition-handwriting-sheet-download-link-arrow {    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.download-image-link-arrow-img {
    display: flex;
    justify-self: center;
    align-self: center;
    height: auto;
    width: 2vw;
    transform: rotate(90deg);
}

.modal-competition-handwriting-syllabus-exercises-right {
    display: flex;
    flex-direction: column;
    border-left: 0.075vw solid rgb(99, 99, 99);
    overflow-y: auto;

}

.modal-handwriting-exercises-list {
    overflow-y: auto;
    padding-left: 1vw;
    height: 40vh;
}

.modal-handwriting-exercise {
    font-size: 1.25vw;
    font-weight: 400;
    padding-bottom: 1.5vw;
    text-align: justify;
    padding-right: 1vw;
}

.modal-handwriting-exercises-list::-webkit-scrollbar-thumb {
    border-radius: 0;
    border: 0.15vw solid rgb(194, 194, 194);
    background-color: #ffffff;
}

.modal-handwriting-exercises-list::-webkit-scrollbar-track { 
    background-color: #000000;
}

.modal-competition-theme-image {
    padding-left: 3.5%;
    width: 90%;
}

.modal-competition-theme-image img {
    height: auto;
    width: 100%;
}