.home {
    display: flex;
    width: 100%;
    background-color: #ffffff;
}

.background-mobile {
    position: relative;
    width: auto;
    height: 80vh;
    width: 100vw;
    margin: 0;
    padding: 0;
    overflow: hidden; /* Hides the part of the image that overflows */
    display: flex; /* Enables flexbox */
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
}

.background-mobile img {
    height: 80vh; /* Same height as the container */
    width: auto; /* Auto width to maintain aspect ratio */
}

.background-mobile.loading {
    padding-bottom: 0vh;
    /* For a 16:9 aspect ratio image */
}

.overlay-content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;
    /* Ensure it's above the image */
}

.background-desktop {
    position: relative;
    width: 100vw;
    margin: 0;
    padding: 0;
    /* Full viewport width */
}

.background-desktop.loading {
    padding-bottom: 48vw;
    /* For a 16:9 aspect ratio image */
}

.home-contents {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
    background-color: #ffffff;
}

.home-contents-desktop {
    display: grid;
    grid-template-rows: auto auto;
    overflow-x: hidden;
    /* padding-top: 5vw */
}

.home-top {
    display: flex
}

.home-title-text {
    margin: 0;
    padding: 0;
    padding-top: 4.65vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 7.5vw;
    font-weight: 700;
    color: #ffffff;
    line-height: 6.5vw;
    letter-spacing: 0.1vw;
    -webkit-text-stroke: 0.15vw #000000;
}

.home-title-subtext {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 5vw;
    font-weight: 700;
    color: #000000;
}

.home-competitions-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 3vw;
    padding: 3vw;
    padding-right: 3.75vw;
    background-color: #e7e7e7;
}

.mobile-home-competitions-list {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    row-gap: 5vh;
    padding: 5vh;
    background-color: #e7e7e7;
}

.home-competitions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.1vw solid #000000;
    border-radius: 0.5vw;
}

.mobile-home-competitions-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 1vh;
}

.home-drawing-competition-container {
    background-color: #00bfff;
}

.home-colouring-competition-container {
    background-color: #18ff6d;
}

.home-handwriting-competition-container {
    background-color: #fff75c;
}

.home-competitions-container-title {
    font-size: 2vw;
    padding: 2vw 0;
    font-weight: 900;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #6f0000;
    color: #fff;
    width: 100%;
}

.mobile-home-competitions-container-title {
    font-size: 2.5vh;
    padding: 3vh 0;
    font-weight: 900;
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #2f0051;
    color: #ffffff;
    width: 100%;
}

.home-drawing-competition-container-title {
    background-color: #2f0051;
}

.home-colouring-competition-container-title {
    background-color: #00332b;
}

.home-handwriting-competition-container-title {
    background-color: #581500;
}

.home-competitions-container-image {
    padding: 1vw;
    margin-top: 1vw;
    display: flex;
    justify-content: center;
}

.mobile-home-competitions-container-image {
    padding: 1vh;
    margin-top: 1vh;
    display: flex;
    justify-content: center;
}

.home-competition-img {
    width: 60%;
    height: auto;
    border-radius: 1vw;
    padding: 1vw 1vw;
    background-color: #ffffff;
}

.mobile-home-competition-img {
    width: 80%;
    height: auto;
    border-radius: 1vh;
    padding: 1vh 1vh;
    background-color: #ffffff;
    border: 0.2vh solid black;
}

.home-competitions-container-text {
    font-size: 2.15vw;
    /* Adjust font size */
    text-wrap: nowrap;
    font-weight: 600;
    letter-spacing: 0.025vw;
    padding: 1vw 0;
}

.mobile-home-competitions-container-text {
    font-size: 3.5vh;
    /* Adjust font size */
    text-wrap: nowrap;
    font-weight: 600;
    letter-spacing: 0.025vw;
    padding: 2vh 0;
}

.home-competitions-container-links {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1vw;
    margin-top: 0;
    margin-bottom: 2vw;
}

.mobile-home-competitions-container-links {
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 2vh;
    margin-top: 1vh;
    margin-bottom: 4vh;
    padding: 2vh;
    border-radius: 1vh;
    background-color: #ffffff;
    border: 0.5vh dashed black;
}

.home-competitions-container-link {
    font-size: 1.25vw;
    padding: 0.75vw;
    border-radius: 1vw;
    border: 0.3vw double white;
    color: #fff;
    background-image: linear-gradient( to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.mobile-home-competitions-container-link {
    font-size: 2.75vh;
    padding: 1.25vh;
    border-radius: 1vh;
    border: 0.5vh double white;
    color: #fff;
    background-image: linear-gradient( to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.home-competitions-container-link:hover {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #ffffff 0%, #ffffff 60%, #ffffff 100%);
}

.home-competitions-container-link:active {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient( to right, #dedede 0%, #dedede 60%, #dedede 100%);
}

.home-competitions-container-link-arrow {    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.home-competitions-container-link-arrow {    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.mobile-home-competitions-container-link-arrow {    
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 2vh;
}

.link-arrow-img {
    display: flex;
    justify-self: center;
    align-self: center;
    height: auto;
    width: 2vw;
}

.mobile-link-arrow-img {
    display: flex;
    justify-self: center;
    align-self: center;
    height: auto;
    width: 5vh;
}

.home-contents-mobile {
    display: grid;
    grid-template-rows: auto auto;
    overflow-x: hidden;
    /* padding: 15vh 1.5vh;
    padding-bottom: 5vh; */
}

.mobile-home-top {
    display: grid;
    grid-template-rows: auto auto auto auto;
}

.mobile-home-description-top {
    width: 70%;
    margin-left: 15%;
    margin-top: 1vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #ffffff;
    background-color: #fff;
}

.mobile-home-title-text {
    margin: 0;
    padding: 0;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 10vh;
    font-weight: 700;
    color: #ffffff;
    line-height: 9vh;
    letter-spacing: 0.2vw;
    pointer-events: none;
    -webkit-text-stroke: 0.2vh #000000;
}

.mobile-home-class-title {
    display: flex;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: #ffffff;
    border-radius: 1.5vh;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 3vh;
    height: 7.5vh;
    margin: 0 25%;
    margin-top: 2.5vh;
    pointer-events: none;
}