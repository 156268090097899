.mobile-guidelines-content {
    width: 100%;
    margin: 0;
    padding: 5vw;
    margin-top: 10vh;
    padding-bottom: 5%;
}

.mobile-guidelines-title {
    padding-bottom: 0vw;
    text-decoration: underline;
}

.mobile-guidelines-container{
    display: block;
    box-sizing: border-box;
}

.mobile-guidelines-number-circle-outside {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-guidelines-number-circle {
    border-radius: 50%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 7.5vw;
    height: 7.5vw;
    margin: 2.5vw;
    margin-left: 0vw;
    margin-right: 5vw;
}

.mobile-guidelines-number {
    font-size: 4vw;
    color: #ffffff;
}

.mobile-guidelines-heading {
    font-size: 7vw;
    font-weight: 600;
    color: #0d372f;
    display: grid;
    grid-template-columns: 1fr 5fr;
    text-decoration: underline;
    padding: 4vw 4vw;
    padding-bottom: 0;
    column-gap: 2.5%;
}

.mobile-guidelines-heading-text {
    font-size: 4vw;
    font-weight: 800;
    display: flex;
    text-align: left;
    align-items: center;
    color: #000000;
}

.mobile-guidelines-subheading-text {
    width: 87.5%;
    margin: 0 auto;
    font-size: 3.75vw;
    font-weight: 800;
    display: flex;
    text-align: justify;
    align-items: center;
    color: #000000;
}

.mobile-guidelines-box {
    font-size: 3.5vw;
    font-weight: 500;
    display: flex;
    text-align: justify;
    color: #000000;
    background-color: #f1f1f1;
    flex-direction: column;
    border-bottom: 0.2vw solid #000000;
    padding: 1.25vw;
    margin: 2.5vh 0vw;
}

.mobile-guidelines-detail {
    width: 87.5%;
    margin: 0 auto;
    font-weight: 400;
    padding: 3vw 0vw;
}

.mobile-guidelines-example {
    font-style: italic;
    padding-top: 0;
    font-weight: 500;
}

.mobile-table-container {
    padding: 2vw 0vw;
    padding-bottom: 1vw;
    width: 87.5%;
    margin: 0 auto;
}

.mobile-custom-table {
    width: 100%;
    border-collapse: collapse;
}

.mobile-custom-table td {
    border: 0.1vw solid black;
    padding: 2vw;
    font-size: 3.25vw;
    font-weight: 600;
}

.mobile-custom-table tr:nth-child(odd) {
    background-color: #ecf3fb;
}

.mobile-custom-table tr:nth-child(even) {
    background-color: #fbefef;
}

.mobile-custom-table td:nth-child(1) {
    width: 30%;
}

.mobile-custom-table td:nth-child(2) {
    width: 70%;
}