.mobile-classes {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    transition: opacity 0.3s ease;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
}

.mobile-classes-content {
    width: 100%;
    display: grid;
    grid-template-rows: 25vh auto;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
    /* Ensuring the container fills the viewport */
}

.mobile-classes-text-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.mobile-classes-text-logo-img {
    width: auto;
    height: 7vh;
}

.mobile-classes-top-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
}

.mobile-classes-page-class-image-container {
    height: 18vh;
    width: 18vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.25vw solid black;
    border-radius: 1vh;
    padding: 1vh;
    background-color: #fff;
    box-shadow: 0.01vh 0.01vh #000000,
        0.25vh 0.25vh 0.75vh #000000;
}

.mobile-classes-page-class-image-container img {
    height: 18vh;
    width: auto;
    border-radius: 1vh;
    background-color: #fff;
}

.mobile-classes-top-right-container {
    height: 25vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #000000 0%, #000000 40%, #ffffff 40%, #ffffff 100%);
}

.mobile-classes-top-row {
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
}

.mobile-classes-top-section-deco {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    align-items: end;
}

.mobile-classes-top-section-deco-opposite {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.mobile-classes-deco-img {
    width: auto;
    height: 10vh;
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #c1c1c1e3 0%, #ffb70000 100%);
}

.mobile-classes-deco-img-opposite {
    width: auto;
    height: 10vh;
    transform: scaleX(-1);
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #c1c1c1e3 0%, #ffb70000 100%);
}

.mobile-classes-title-container {
    background-color: #ffffff;
    color: #000000;
}

.mobile-classes-competition-title {
    font-size: 2.5vh;
    border-radius: 0.5vw;
    font-weight: 800;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    text-align: center;
    height: 15vh;
    color: #000000;
}

.mobile-classes-bottom-container {
    display: grid;
    grid-template-rows: 7.5vh auto;
    overflow-y: auto;
    border-top: 0.2vh solid black;
}

.mobile-classes-choose-class-text {
    font-size: 2.5vh;
    border-radius: 0.5vw;
    font-weight: 800;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    text-decoration: underline;
    height: 100%;
    width: 100%;
    background-color: #e4e4e4;
}

.mobile-classes-class-list {
    background-color: #ffffff;
    display: grid;
    overflow-y: scroll;
    padding-top: 10vw;
}

.mobile-classes-class-container {
    align-items: center;
    margin-bottom: 8vw;
    margin-left: 25vw;
    /* spacing between class boxes */
    cursor: pointer;
    transition: all 0.25s ease-in-out;
    /* Added border to the transition */
    aspect-ratio: 16/4;
    border-radius: 1vh;
    display: grid;
    grid-template-rows: 1fr 0fr;
    box-sizing: border-box;
    background-color: #7aff78; /*39d4ff fff478*/
    border: 0.15vh solid #000000;
    /* Added a transparent border so that it doesn't affect layout when it changes color */
    width: 50vw;
    -webkit-tap-highlight-color: transparent;
    box-shadow: 0.75vh 0.75vh;
}

.mobile-classes-class-text {
    display: grid;
    grid-template-columns: 70% 30%;
    
}

.mobile-classes-KG-class-text {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-classes-class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    color: #000000;
}

.mobile-classes-class-title-class-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    color: #000000;
}

