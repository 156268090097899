.footer {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer-mobile {
    height: 70vw;
    background-size: auto 70vw;
    background-position: 50%;
    border-top: 1vw solid black;
}

.footer-logo {
    width: 100%;
    display: flex;
    align-items: center;
}

.footer-logo img {
    height: 4vw;
    width: auto;
    border-radius: 0.5vw;
}

.footer-logo-img {
    justify-content: center;
    align-items: center;
    margin-left: 1vw;
    margin-top: 0.75vw;
}

.footer-name-text-img {
    padding-left: 0.5vw;
}

.mobile-footer-logo {
    width: 100%;
    display: flex;
    justify-content: center;
}

.mobile-footer-logo img {
    height: 6vh;
    padding-top: 10vw;
    border-radius: 0.5vh;
}

.mobile-footer-logo-text {
    font-size: 4.5vw;
    font-weight: 500;
    text-align: center;
    padding-bottom: 3vw;
}

.mobile-footer-name-text-img {
    padding-left: 0.5vh;
}

.footer-top {
    width: 100%;
    height: 100%;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 5vw;
    font-weight: 800;
    color: #ffffff;
    background-image: linear-gradient(to bottom, #16231600, #162316f6);
}

.mobile-footer-top {
    background-image: linear-gradient(to bottom, #000000, #000000);
}

.footer-top-text {
    padding: 10vw 5vw;
}

.footer-bottom {
    background-image: linear-gradient(to bottom, #16231600, #162316);
    width: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, auto);
    /* Repeat the rows twice */
    align-items: left;
    row-gap: 1vw;
}

.mobile-footer-bottom {
    background-image: linear-gradient(to bottom, #000000, #000000);
    padding-bottom: 5vw;
}

.mobile-footer-top-right-button {
    padding-top: 5vw;
}

.footer-bottom-left {
    display: grid;
    grid-template-columns: auto auto;
    padding: 1rem 1rem;
    justify-content: left;
}

.mobile-footer-top {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.mobile-footer-middle {
    display: grid;
    grid-template-rows: 1fr 1fr;
}

.footer-logo-text {
    padding-left: 2.5vw;
    color: #ffffff;
    pointer-events: none;
}

.footer-logo-text-top {
    font-size: 2.5vw;
    font-weight: 500;
}

.footer-logo-text-email {
    font-size: 2vw;
    font-weight: 800;
    text-decoration: underline;
}

.mobile-footer-logo-text-email {
    font-weight: 800;
    text-decoration: underline;
}

.footer-bottom-right {
    display: flex;
    justify-content: end;
}

.footer-bottom-right-button {
    padding: 2vw;
}

.footer-divider {
    height: 0.1vw;
    width: 70%;
    align-self: center;
}

.footer-rights {
    width: 100%;
    justify-content: center;
    text-align: center;
    align-self: center;
    font-size: 1.4vw;
    color: #ffffff;
    padding-bottom: 1vw;
    display: grid;
    grid-column: span 2;
}

.mobile-footer-bottom {
    display: flex;
    flex-direction: column;
    justify-self: center;
    align-items: center;
    font-size: 2.5vw;
    pointer-events: none;
}

.footer-terms-button {
    text-decoration: none;
    color: #ffffff;
    cursor: pointer;
    padding-right: 1rem;
}

.mobile-footer-terms-button {
    text-decoration: none;
    color: black;
    cursor: pointer;
    white-space: nowrap;
    font-size: 3vw;
    padding-right: 0;
}

.mobile-tnc-container-box {
    display: inline-block;
    position: relative;
    padding: 1vh;
    font-size: 3.25vw;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    border: 0.3vh solid #ffffff;
    background-image: linear-gradient(to right, #ffffff30, #000000);
    border-radius: 0.75vh;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.tnc-container-box {
    display: inline-block;
    position: relative;
    padding: 0.35vw;
    font-size: 1.35vw;
    color: #ffffff;
    font-weight: 600;
    border: 1px solid #ffffff;
    padding: 0.75vw;
    border-radius: 1vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.mobile-footer-bottom-line {
    color: #ffffff;
    font-size: 1.75vh;
}