.mobile-themes-content {
    padding-top: 10%;
}

.mobile-themes-title {
    padding-bottom: 0vw;
    white-space: nowrap;
    pointer-events: none;
}

.mobile-classes-list {
    background-color: #057490;
    display: grid;
    padding: 5vw;
    padding-bottom: 0;
}

.mobile-classes-list::-webkit-scrollbar {
    display: none;
    /* Hide scrollbar for Chrome, Safari, and Opera */
}

.mobile-expanded-class {
    margin: 7.5%;
    margin-bottom: 0;
}

.mobile-class-container {
    align-items: center;
    margin-bottom: 4vh;
    /* spacing between class boxes */
    cursor: default;
    transition: all 0.3s ease-in-out;
    /* Added border to the transition */
    aspect-ratio: 16/4;
    border-radius: 1vh;
    display: grid;
    grid-template-rows: 1fr 0fr;
    box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    background-color: #dcdcdc;
    border: 0.75vh double transparent;
    /* Added a transparent border so that it doesn't affect layout when it changes color */
}

.mobile-class-container.expanded {
    grid-template-rows: 1fr 1fr;
    transition: all 0.3s ease-in-out 0.25s, border 0.25s;
    /* Added a delay and also transition for border */
    aspect-ratio: 1/1.15;
    background-color: #ffffff;
    animation: mobile-tempBorder 0.25s forwards;
    /* Added an animation */
}

.mobile-class-container.collapsed {
    grid-template-rows: 1fr 1fr;
    transition: all 0.3s ease-in-out 0.25s, border 0.25s;
    /* Added a delay and also transition for border */
    aspect-ratio: 1/1.15;
    background-color: #ffffff;
    animation: mobile-tempBorder 0.25s forwards;
    /* Added an animation */
}

.mobile-class-container.scroll-triggered {
    animation: mobile-tempBorder 0.25s forwards;
    transition-delay: 0.25s;
    /* Longer delay when scrolled */
}

@keyframes mobile-tempBorder {

    0%,
    100% {
        border-color: transparent;
    }

    1% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }

    99% {
        border-color: rgb(0, 0, 0);
        background-color: #ffffff;
    }
}

.mobile-class-view-syllabus-button {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: #1c1c1c;
    border-radius: 4vh;
    white-space: nowrap;
    text-align: center;
    font-weight: 600;
    font-size: 2.25vh;
    border: 0vh double #ffffff;
    opacity: 0;
}

.expanded .mobile-class-view-syllabus-button {
    height: 7.5vh;
    width: auto;
    margin: 1vh;
    border: 0.5vh double #ffffff;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.75s, border 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.mobile-class-view-syllabus-button.full-opacity {
    opacity: 1;
    transition: opacity 0.6s ease;
}

.mobile-class-image {
    height: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.expanded .mobile-class-image {
    height: 30vh;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.scroll-triggered .mobile-class-image {
    height: 30vh;
    width: auto;
    transition: all 0.3s ease-in-out, height 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.mobile-class-image img {
    height: 30vh;
    width: auto;
}

.mobile-class-text {
    display: grid;
    grid-template-columns: 35% 65%;
}

.expanded .mobile-class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.75s;
}

.scroll-triggered .mobile-class-text {
    display: grid;
    grid-template-columns: 50% 50%;
    transition: all 0.3s ease-in-out 0.75s;
}

.mobile-class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    color: #2f2f2f;
    pointer-events: none;
}

.expanded .mobile-class-title {
    font-size: 4vh;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
}

.scroll-triggered .mobile-class-title {
    font-size: 4vh;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
}

.mobile-class-title-class-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    color: #2f2f2f;
    pointer-events: none;
}

.expanded .mobile-class-title-class-number {
    justify-content: center;
    font-size: 9vh;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.scroll-triggered .mobile-class-title-class-number {
    justify-content: center;
    font-size: 9vh;
    font-weight: 900;
    color: #000000;
    transition: all 0.3s ease-in-out 0.75s;
    /* Added delay for the height transition */
}

.no-scroll {
    overflow: hidden;
    position: fixed;
    width: 100%;
    height: 100%;
}

.sticky-scroll {
    position: fixed;
    /* make it stick to its position */
    top: 0;
    /* align it to the top of the viewport */
    left: 0;
    /* align it to the left of the viewport */
    right: 0;
    /* align it to the right of the viewport */
    overflow-y: scroll;
    /* force vertical scrolling */
}

.mobile-classlist-topic {
    padding-left: 2vh;
    padding-top: 2vh;
    font-size: 3vh;
    font-weight: 600;
    color: #000000;
    pointer-events: none;
}

.mobile-classlist-scroll-container {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 2vh;
    padding-right: 0vh;
}

/* Hide scrollbar but still allow scrolling */
.scroll-container::-webkit-scrollbar {
    display: none;
}

.mobile-clickable-class-container {
    align-items: center;
    margin-bottom: 1vh;
    /* spacing between class boxes */
    cursor: default;
    /* Added border to the transition */
    aspect-ratio: 1/1.25;
    border-radius: 1vh;
    display: grid;
    margin-right: 2vh;
    box-shadow: .5vh .5vh .5vh rgba(0, 0, 0, 0.75);
    box-sizing: border-box;
    background-color: #ffffff;
    border: 0.75vh double transparent;
    /* Added a transparent border so that it doesn't affect layout when it changes color */
}

.mobile-clickable-class-text {
    display: grid;
    grid-template-rows: 35% 65%;
    padding: 1vh;
}

.mobile-clickable-class-title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3vh;
    font-weight: 600;
    color: #2f2f2f;
    pointer-events: none;
}

.mobile-clickable-class-title-class-number {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    justify-content: center;
    font-size: 5vh;
    font-weight: 800;
    color: #000000;
    pointer-events: none;
}