.landingPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    background-color: #ffffff;
    margin-bottom: 0;
}

.landingPage-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    right: 0;
    opacity: 0;
    width: 100%;
    padding: 0vw 0vw;
    box-sizing: border-box;
    background: transparent;
    background-color: #ffffff;
    transition: opacity 0.25s ease, background-color 0.25s ease;
}

.landingPage-content-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.landingPage-contents {
    overflow: overlay;
    scroll-behavior: smooth;
}

.landingPage-contents::-webkit-scrollbar {
    width: 0.75vw;
}

.landingPage-scrollbar-hidden::-webkit-scrollbar {
    width: 0px;
}

.landingPage-contents::-webkit-scrollbar-thumb {
    border-radius: 5px;
    border: 0.15vw solid rgb(194, 194, 194);
    background-color: #ff8800;
}

.landingPage-contents::-webkit-scrollbar-track {
    background-color: #000000;
}

.loading-shimmer {
    animation: fadeInFromNone 1.75s linear infinite;
}

@keyframes fadeInFromNone {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.5;
    }
}

.loading-spinner {
    height: 60px;
    width: 60px;
    position: relative;
    top: 50%;
    left: 50%;
    margin: -30px 0 0 -30px;
    -webkit-animation: loading-spinner-rotation 1s infinite linear;
    -moz-animation: loading-spinner-rotation 1s infinite linear;
    -o-animation: loading-spinner-rotation 1s infinite linear;
    animation: loading-spinner-rotation 1s infinite linear;
    border: 6px solid rgba(0, 0, 0, 0.2);
    border-radius: 100%;
}

.loading-spinner:before {
    content: "";
    display: flex;
    position: relative;
    left: -6px;
    top: -6px;
    height: 100%;
    width: 100%;
    border-top: 6px solid rgba(209, 209, 209, 0.8);
    border-left: 6px solid transparent;
    border-bottom: 6px solid transparent;
    border-right: 6px solid transparent;
    border-radius: 100%;
}

@-webkit-keyframes loading-spinner-rotation {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(359deg);
    }
}

@-moz-keyframes loading-spinner-rotation {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(359deg);
    }
}

@-o-keyframes loading-spinner-rotation {
    from {
        -o-transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(359deg);
    }
}

@keyframes loading-spinner-rotation {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}