.scroll-button {
  position: fixed;
  border-radius: 50%;
  z-index: 998;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  box-shadow: 0.25vw 0.25vw 1vw inset #bdbdbd;
  transition: transform 0.3s, background-color 0.3s;
  cursor: pointer;
}

.scroll-button:active,
.scroll-button:focus,
.scroll-button:hover {
  background-color: #ffffff; /* Override any pseudo-class styling */
}

.scroll-button.up {
  transform: rotate(-180deg);
}

@keyframes jump {

  0%,
  20%,
  40%,
  100% {
    transform: translateY(0);
  }

  10%,
  30% {
    transform: translateY(-15px);
  }
}

.scroll-button.down {
  transform: rotate(0deg);
  box-shadow: 0.25vw 0.25vw 1vw inset rgba(0, 0, 0, 0.3);
}

.scroll-button.down.jump {
  animation: jump 1.5s infinite;
  animation-delay: 0.5s;
}

.scroll-button img {
  width: auto;
  height: 2.5vh;
}