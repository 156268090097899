.landingPage-side-drawer {
    height: 100vh;
    background: #ffffff;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    transform: translateX(100%);
    transition: transform 0.3s ease-out;
}

.landingPage-side-drawer.open {
    transform: translateX(0);
}

.landingPage-navbar-side-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 10vh;
    padding: 0rem 1rem;
    background: linear-gradient(to right, #ffffff 0%, #d2fff8 100%);
    border-bottom: 1px solid black;
    transition: opacity 0.25s ease, background-color 0.25s ease, border-bottom 0.25s ease, box-shadow 0.25s ease;
}

.landingPage-side-drawer-close-icon {
    display: flex;
    height: 5vw;
    margin-right: 1vw;
    background-color: #000000;
    padding: 1vh;
    border-radius: 1vh;
}

.landingPage-navbar-links-mobile {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.landingPage-navbar-links-container-mobile {
    display: flex;
    flex-direction: column;
    padding-top: 3vh;
}

.landingPage-navbar-links-container-mobile.mobile-sidebar-bottom {
    padding-top: 0%;
}

.landingPage-navbar-links-container-box-mobile {
    display: flex;
    padding: 0.35vw;
    font-size: 1.5vw;
    color: #ffffff;
    letter-spacing: 0.075vw;
    font-weight: 600;
    background-color: var(--category-color);
    border: 0.5vw solid #000000;
    border-radius: 1.5vw;
    white-space: nowrap;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 2vh 4vh;
    font-weight: 600;
    font-size: 2.5vh;
    line-height: 10vw;
}

.landingPage-navbar-links-container-box-mobile.normal-box {
    color: #000000;
    background-color: #dae8f0;
    border: 0.5vw solid #dae8f0;
}

.landingPage-navbar-links-container-box-mobile.black-box {
    color: #ffffff;
    background-color: #000000;
}

.landingPage-navbar-links-container-box-mobile.gold-box {
    color: #000000;
    background-color: #f1c378;
    border: 0.5vw solid #f1c378;
}

.landingPage-navbar-links-container-box-mobile.black-box:active {
    background-color: #353535;
    color: #ffffff;
}

.landingPage-navbar-links-container-box-mobile.gold-box:active {
    background-color: #9c7e4e;
    color: #000000;
}

.landingPage-navbar-links-container-text-mobile-box-text{
    display: inline-block;
    cursor: default;
}

