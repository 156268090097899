.mobile-syllabus {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    transition: opacity 0.3s ease;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
}

.mobile-syllabus-content {
    width: 100%;
    display: grid;
    grid-template-rows: 25vh auto;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
    /* Ensuring the container fills the viewport */
}

.mobile-syllabus-top-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
}

.mobile-syllabus-page-class-image-container {
    height: 18vh;
    width: 18vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.25vw solid black;
    border-radius: 1vh;
    padding: 1vh;
    background-color: #fff;
    box-shadow: 0.01vh 0.01vh #000000,
        0.25vh 0.25vh 0.75vh #000000;
}

.mobile-syllabus-page-class-image-container img {
    height: 18vh;
    width: auto;
    border-radius: 1vh;
    background-color: #fff;
}

.mobile-syllabus-top-right-container {
    height: 25vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #000000 0%, #000000 40%, #ffffff 40%, #ffffff 100%);
    ;
}

.mobile-syllabus-text-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.mobile-syllabus-text-logo-img {
    width: auto;
    height: 7vh;
}

.mobile-syllabus-top-row {
    height: 10vh;
    width: 100%;
    display: grid;
    background-color: var(--competition-top-colour);
}

.mobile-syllabus-top-section-deco {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    align-items: end;
}

.mobile-syllabus-top-section-deco-opposite {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.mobile-syllabus-deco-img {
    width: auto;
    height: 10vh;
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffffe3 0%, #ffb70000 100%);
}

.mobile-syllabus-deco-img-opposite {
    width: auto;
    height: 10vh;
    transform: scaleX(-1);
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
    background: linear-gradient(to top left, #ffffffe3 0%, #ffb70000 100%);
}

.mobile-syllabus-title-container {
    background-color: #ffffff;
}

.mobile-syllabus-competition-title {
    height: 15vh;
    font-size: 3vh;
    border-radius: 0.5vw;
    font-weight: 800;
    position: relative;
    justify-content: center;
    align-items: flex-end;
    justify-self: center;
    justify-items: center;
    align-self: center;
    color: #000000;
    display: grid;
    grid-template-rows: auto auto;
    grid-auto-rows: min-content;
}

.mobile-syllabus-competition-title-bottom {
    background-color: #3d3d3d;
    border: 0.35vh solid #000000;
    color: #fff;
    padding: 1.5vh 3vh;
    border-radius: 0.5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
}

.mobile-syllabus-bottom-container {
    border-top: 0.2vh solid black;
    display: grid;
    grid-template-rows: repeat(auto-fill, auto);
    background-color: #eaeaea;
    overflow-y: auto;
    grid-auto-rows: min-content;
}

.mobile-competition-themes {
    width: 100%;
}

.mobile-competition-themes-initial-text {
    font-size: 2.5vh;
    margin: 1vh;
    text-align: center;
    margin-top: 2vh;
    padding: 2vh 1vh;
    font-weight: 500;
    color: #000000;
    text-decoration: none;
    border-radius: 0.5vh;
    background-color: #d8adff;
}

.mobile-competition-drawing-syllabus-item {
    margin: 1vh;
    padding: 1vh;
    border-radius: 1vh;
    margin-bottom: 2vh;
    font-size: 2.5vh;
    font-weight: 500;
    text-align: left;
    color: #000000;
    display: grid;
    grid-template-rows: auto auto;
    background-color: #ffffff;
    border: 0.1vh dashed black;
}

.mobile-competition-syllabus-top-row {
    display: grid;
    grid-template-columns: 10% 5% auto;
    column-gap: 2.5%;
    padding-bottom: 1vh;
    border-bottom: 1px solid #c4c4c4;
}

.mobile-competition-drawing-syllabus-container-divider {
    display: flex;
    align-items: center;
    justify-content: center;

}

.mobile-competition-syllabus-number-circle {
    height: 5vh;
    width: 5vh;
    border-radius: 2.5vh;
    font-size: 2.5vh;
    color: #000000;
    background-color: #d8adff;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    align-self: center;
}

.mobile-competition-drawing-syllabus-type {
    background-color: #ffe5a3;
    border-radius: 1vh;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    padding: 1vh;
    font-weight: 700;
    height: 5vh;
}

.mobile-competition-drawing-syllabus-description {
    text-align: left;
    margin: 1vh;
    font-size: 2.25vh;
}




.mobile-competition-colouring-syllabus-container {
    font-weight: 500;
    display: grid;
    grid-template-rows: 1fr 1fr;
    row-gap: 2vh;
}

.mobile-competition-colouring-syllabus-item {
    margin: 1vh;
    padding: 1vh;
    background-color: #ffffff;
    display: grid;
    grid-template-rows: auto auto 7vh;
    row-gap: 5vw;
    border-radius: 1vh;
    padding-bottom: 4vh;
    border: 0.1vh dashed black;
}

.mobile-competition-colouring-syllabus-item-info {
    display: grid;
    grid-template-columns: 10% auto;
}

.mobile-competition-syllabus-number-circle-colouring {
    align-self: center;
    height: 5vh;
    width: 5vh;
    border-radius: 2.5vh;
}

.mobile-competition-colouring-syllabus-description {
    display: flex;
    align-items: center;
    align-self: center;
    font-size: 2.25vh;
    line-height: 2.25vh;
    padding: 0;
    padding-left: 4vw;
}

.mobile-competition-colouring-image {
    margin: 1vh 0;
    margin-left: 10vw;
    width: 70vw;
    height: 40vw;
    border: 0.25vh solid #dbdbdb;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1vh;
}

.mobile-display-colouring-img {
    border-radius: 1vh;
    height: auto;
    width: 100%;
}

.mobile-competition-colouring-image-download-link {
    margin: 0 15vw;
    font-size: 2.25vh;
    padding: 0.75vw;
    border-radius: 1.5vh;
    border: 0.5vh double white;
    color: #fff;
    background-image: linear-gradient(to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.mobile-competition-colouring-image-download-link:hover {
    transition: all 0.3s ease-in-out;
    border: 0.5vh double black;
    color: #000000;
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 60%, #ffffff 100%);
}

.mobile-competition-colouring-image-download-link:active {
    transition: all 0.3s ease-in-out;
    border: 0.5vh double black;
    color: #000000;
    background-image: linear-gradient(to right, #dedede 0%, #dedede 60%, #dedede 100%);
}

.mobile-competition-colouring-image-download-link-text {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    text-align: center;
}

.mobile-competition-colouring-image-download-link-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.mobile-download-image-link-arrow-img {
    display: flex;
    justify-self: center;
    align-self: center;
    height: auto;
    width: 4vh;
    transform: rotate(90deg);
}





.mobile-competition-handwriting-syllabus-container {
    padding: 0.5vw 1vw;
    font-size: 1.25vw;
    font-weight: 500;
    display: grid;
    grid-template-rows: auto auto;
    column-gap: 5vw;
}

.mobile-competition-handwriting-syllabus-sheet-left {
    display: grid;
    grid-template-rows: 1fr auto;
    margin: 1vh;
    padding: 1vh;
    border-radius: 1vh;
    padding-bottom: 4vh;
    background-color: #ffffff;
}

.mobile-competition-handwriting-syllabus-sheet-info {
    display: grid;
    grid-template-columns: 12.5% 80% 7.5%;
}

.mobile-competition-handwriting-syllabus-description {
    display: flex;
    align-items: center;
    align-self: center;
}

.mobile-competition-handwriting-sheet {
    margin: 1vw 0;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 100%;
}

.mobile-display-handwriting-img {
    border-radius: 1vw;
    border: 0.5vh solid #dbdbdb;
    background-color: #fff;
    padding: 1vh;
    height: auto;
    width: 50vw;
    margin-bottom: 2.5vh;
}

.mobile-competition-handwriting-sheet-download-link {
    margin: 0 15vw;
    font-size: 2.25vh;
    padding: 0.75vw;
    border-radius: 1.5vh;
    border: 0.5vh double white;
    color: #fff;
    background-image: linear-gradient(to right, #2b2b2b 0%, #000000 60%, #000000 100%);
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    user-select: none;
}

.mobile-competition-handwriting-sheet-download-link:hover {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient(to right, #ffffff 0%, #ffffff 60%, #ffffff 100%);
}

.mobile-competition-handwriting-sheet-download-link:active {
    transition: all 0.3s ease-in-out;
    border: 0.3vw double rgb(0, 0, 0);
    color: #000000;
    background-image: linear-gradient(to right, #dedede 0%, #dedede 60%, #dedede 100%);
}

.mobile-competition-handwriting-sheet-download-link-text {
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    text-align: center;
}

.mobile-competition-handwriting-sheet-download-link-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.5vw;
}

.mobile-competition-handwriting-syllabus-exercises-right {
    display: flex;
    flex-direction: column;
    border: 0.25vh solid rgb(99, 99, 99);
    overflow-y: auto;
    border-radius: 0.5vh;
    margin: 0 1vh;
    margin-bottom: 2vh;
    background-color: #ffffff;
}

.mobile-competition-themes-handwriting-sub-title-text {
    font-size: 2.5vh;
    margin: 0vh;
    padding: 1.5vh;
    font-weight: 500;
    text-align: left;
    color: #000000;
    text-decoration: none;
    background-color: #ffe5a3;
    border-bottom: 0.1vh solid black;
}

.mobile-handwriting-exercises-list {
    padding-left: 1vw;
    margin: 1vh;
    padding: 1vh;
    border-radius: 1vh;
    background-color: #ffffff;
}

.mobile-handwriting-exercise {
    font-size: 2vh;
    font-weight: 400;
    padding-bottom: 5vw;
    text-align: justify;
    padding-right: 1vw;
}