.gallery {
    display: flex;
    width: 100%;
    background-color: #ffffff;
}

.gallery-contents {
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.gallery-contents-desktop {
    display: grid;
    grid-template-rows: auto auto;
    overflow-x: hidden;

    /* padding-top: 5vw */
}

.gallery-top {
    display: flex
}

.gallery-title {
    padding-bottom: 2.5vw;
}

.gallery-title-text {
    margin: 0;
    padding: 0;
    padding-top: 2.65vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 4vw;
    font-weight: 700;
    color: #000000;
    line-height: 5vw;
    letter-spacing: 0.1vw;
}

.gallery-title-subtext {
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    color: #000000;
}

.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Adjust for button spacing */
    margin: 1vw 0;
    margin-bottom: 3vw;
    overflow: hidden;
    width: 100vw;
    position: relative;
    /* Needed for absolute positioning of children */
}

.divs-container {
    display: flex;
    width: 100vw;
    /* Adjust to fit the container size */
    position: relative;
    height: 40vw;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    /* Ensure divs stay behind the buttons */
}

.div-item {
    transition: transform 1s ease, opacity 1s ease;
    width: 40vw;
    margin: 10vw 1vw;
    height: 20vw;
    text-align: center;
    position: absolute;
    opacity: 0;
    /* Initially hidden */
    z-index: 3;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
}

.div-item.position-0 {
    transform: translateX(-150vw);
    /* Off-screen */
}

.div-item.position-1 {
    transform: translateX(-100vw);
    /* Off-screen */
    opacity: 0.5;
}

.div-item.position-2 {
    transform: translateX(-50vw);
    /* Partially visible */
    opacity: 0.75;
}

.div-item.position-3 {
    transform: translateX(0vw);
    /* Center */
    opacity: 1;
}

.div-item.position-4 {
    transform: translateX(50vw);
    /* Partially visible */
    opacity: 0.75;
}

.div-item.position-5 {
    transform: translateX(100vw);
    /* Off-screen */
    opacity: 0.5;
}

.div-item.position-6 {
    transform: translateX(150vw);
    /* Off-screen */
    opacity: 0;
}

.info-box-top,
.info-box-bottom {
    position: absolute;
    background-color: #000000;
    color: #ffffff;
    transition: opacity 0.5s ease, transform 1s ease;
    opacity: 0;
    /* Start hidden */
    z-index: 1;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
}

.info-box-top {
    background-image: linear-gradient(to bottom right, var(--top-color1), var(--top-color2));
    border: 0.25vw solid var(--top-border-color);
    color: #313131;
    width: 30vw;
    /* Adjust as needed */
    height: 4vw;
    bottom: 100%;
    /* Start below the div */
    left: 50%;
    font-size: 1.5vw;
    font-weight: 700;
    border-radius: 0.5vw;
    transform: translateX(-50%) translateY(2vw);
    /* Initial transform */
    text-align: center;
}

.div-item-img {
    background-image: linear-gradient(to bottom right, var(--middle-color1), var(--middle-color2));
    border: 0.5vw solid white;
    box-shadow: 1vw 1vw 1.5vw rgba(0, 0, 0, 0.75);
    border-radius: 1vw;
    width: 34vw;
    height: 20vw;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
    position: absolute;
    opacity: 1;
    /* Initially hidden */
    z-index: 3;
}

.div-item-img img {
    height: auto;
    width: 30vw;
    transition: transform 1s ease;
    border: 0.2vw solid transparent;
    border-radius: .5vw;
}

.info-box-bottom {
    background-image: linear-gradient(to bottom right, var(--bottom-color1), var(--bottom-color2));
    border: 0.5vw solid #dcdcdc;
    border-radius: 1vw;
    width: 42.5vw;
    height: 15vw;
    top: 100%;
    /* Start above the div */
    left: 50%;
    transform: translateX(-50%) translateY(-10vw);
    /* Initial transform */
    display: grid;
    grid-template-rows: auto auto;
}

.info-box-bottom-content {
    padding-top: 4vw;
}

.info-box-bottom-row-1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    /* Align vertically */
    justify-content: space-between;
    /* Align horizontally */
}

.info-box-bottom-text-box {
    background-color: #ffffff3f;
    color: #ffffff;
    padding: 0.75vw;
    border: 0.2vw solid #ffffff27;
    border-radius: 0.5vw;
    margin: 0.5vw;
    font-size: 1.25vw;
    font-weight: 500;
}

.info-box-bottom-school {
    padding-left: 3vw;
    padding-right: 3vw;
}

.div-item.position-3 {
    transform: translateY(-3vw);
    /* Move up when centered */
}

.div-item.position-3 .info-box-top {
    opacity: 1;
    /* Make visible */
    transform: translateX(-50%) translateY(-2vw);
    /* Reset transform */
}

.div-item.position-3 .info-box-bottom {
    opacity: 1;
    /* Make visible */
    transform: translateX(-50%) translateY(-4vw);
    /* Reset transform */
}

.visible {
    opacity: 1;
}


/* Button styles */
.gallery-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
    background-color: #ffa200;
    border: 0.1vw solid #d78800;
    color: #000000;
    cursor: pointer;
    padding: 0.8vw;
    border-radius: 50%;
    /* Make buttons round */
    font-size: 2.5vw;
    /* Adjust icon size */
    display: flex;
    align-items: center;
    justify-content: center;
}

.rotate-gallery-button {
    display: block;
    margin: 0 auto;
    transform: rotate(180deg) translateY(50%);
    transform-origin: center;
}

.gallery-button:hover {
    background-color: #fcb02d;
}

/* Left button */
.gallery-button:first-of-type {
    left: calc(23.5vw);
    /* Position the left button with gap */
}

/* Right button */
.gallery-button:last-of-type {
    right: calc(23.5vw);
    /* Position the right button with gap */
}

.gallery-contents-mobile {
    display: grid;
    grid-template-rows: auto auto;
    overflow-x: hidden;
    /* padding-top: 5vw */
}

.mobile-gallery-title {
    padding-bottom: 3vh;
}

.mobile-gallery-title-text {
    margin: 0;
    padding: 0;
    padding-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    box-sizing: border-box;
    font-size: 4vh;
    font-weight: 700;
    color: #000000;
    letter-spacing: 0.1vh;
}

.mobile-gallery-title-subtext {
    margin: 5vw;
    margin-top: 3vh;
    margin-bottom: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 2vh;
    font-weight: 400;
    box-sizing: border-box;
    color: #000000;
}

.mobile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* Adjust for button spacing */
    margin: 2.5vh 0;
    overflow: hidden;
    width: 100vw;
    position: relative;
    /* Needed for absolute positioning of children */
}

.mobile-divs-container {
    display: flex;
    width: 100vw;
    /* Adjust to fit the container size */
    position: relative;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    /* Ensure divs stay behind the buttons */
}

.mobile-div-item {
    transition: transform 1s ease, opacity 1s ease;
    width: 70vw;
    margin: 0vh 10vw;
    text-align: center;
    position: absolute;
    opacity: 0;
    /* Initially hidden */
    z-index: 3;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
}

.mobile-div-item.position-0 {
    transform: translateX(-150vw);
    /* Off-screen */
}

.mobile-div-item.position-1 {
    transform: translateX(-100vw);
    /* Off-screen */
    opacity: 0.5;
}

.mobile-div-item.position-2 {
    transform: translateX(-80vw);
    /* Partially visible */
    opacity: 0.75;
}

.mobile-div-item.position-3 {
    transform: translateX(0vw);
    /* Center */
    opacity: 1;
}

.mobile-div-item.position-4 {
    transform: translateX(80vw);
    /* Partially visible */
    opacity: 0.75;
}

.mobile-div-item.position-5 {
    transform: translateX(100vw);
    /* Off-screen */
    opacity: 0.5;
}

.mobile-div-item.position-6 {
    transform: translateX(150vw);
    /* Off-screen */
    opacity: 0;
}


.mobile-info-box-bottom {
    position: absolute;
    background-color: #000000;
    color: #ffffff;
    transition: opacity 1s ease, transform 1s ease;
    opacity: 0;
    /* Start hidden */
    z-index: 1;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
}

.mobile-info-box-top {
    background-image: linear-gradient(to bottom right, #ffd632, #ffe26c);
    border: 0.2vw solid black;
    color: #000000;
    width: 80vw;
    margin-top: 5vh;
    margin-left: 10vw;
    /* Adjust as needed */
    height: 5vh;
    /* Start below the div */
    font-size: 2vh;
    font-weight: 500;
    /* Initial transform */
    border-radius: 1vh;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    z-index: 4;
}

.mobile-div-item-img {
    background-image: linear-gradient(to bottom right, var(--middle-color1), var(--middle-color2));
    border: 0.5vh solid white;
    box-shadow: 1vh 1vh 1.5vh rgba(0, 0, 0, 0.75);
    border-radius: 2vw;
    width: 70vw;
    padding: 2.5vw 0;
    display: flex;
    align-items: center;
    /* Align vertically */
    justify-content: center;
    /* Align horizontally */
    position: absolute;
    top: 5vh;
    opacity: 1;
    /* Initially hidden */
    z-index: 3;
}

.mobile-div-item-img img {
    width: 65vw;
    transition: transform 1s ease;
    border: 0.2vw solid transparent;
    border-radius: 1.5vh;
}

.mobile-info-box-bottom {
    background-image: linear-gradient(to bottom right, var(--bottom-color1), var(--bottom-color2));
    border: 0.5vh solid #dcdcdc;
    border-radius: 2vw;
    width: 82.5vw;
    height: 30vh;
    /* Start above the div */
    left: 50%;
    transform: translateX(-50%) translateY(25vw);
    /* Initial transform */
    display: grid;
    grid-template-rows: auto auto auto;
}

.mobile-info-box-bottom-content {
    padding: 2.5vw;
    padding-top: 5vh;
}

.mobile-info-box-bottom-text-box {
    background-color: #ffffff3f;
    color: #ffffff;
    padding: 0.75vw;
    border: 0.2vw solid #ffffff27;
    border-radius: 0.5vw;
    margin: 1.5vh;
    font-size: 2.25vh;
    font-weight: 400;
}

.mobile-info-box-bottom-school {
    padding-left: 3vw;
    padding-right: 3vw;
}

.mobile-div-item.position-3 {
    transform: translateY(-5vh);
    /* Move up when centered */
    z-index: 5;
}

.mobile-div-item.position-3 .info-box-top {
    opacity: 1;
    /* Make visible */
    transform: translateX(-50%) translateY(-5vh);
    /* Reset transform */
}

.mobile-div-item.position-3 .info-box-bottom {
    opacity: 1;
    /* Make visible */
    transform: translateX(-50%) translateY(-15vw);
    /* Reset transform */
}

.visible {
    opacity: 1;
}

.mobile-gallery-button-outer-shell {
    position: absolute;
    top: 40%;
    transform: translateX(-27.5vw) translateY(-50%);
    border-radius: 50%;
    z-index: 3;
    transform-origin: center;
    background: radial-gradient(circle, #3b3b3b 0%, #ffffff00 70%);
    width: 40vw;
    height: 40vw;
    display: flex;
    align-items: center; /* Centers child vertically */
    justify-content: center; /* Centers child horizontally */
}

.mobile-rotate-gallery-button-outer-shell {
    display: block;
    margin: 0 auto;
    transform: rotate(180deg) translateX(-27.5vw) translateY(50%);
    transform-origin: center;
    display: flex;
    align-items: center; /* Centers child vertically */
    justify-content: center; /* Centers child horizontally */
}

/* Button styles */
.mobile-gallery-button {
    margin-left: 17.5vw;
    background-color: #ffff57;
    border: 0.1vh solid #000000;
    color: #000000;
    cursor: pointer;
    padding: 1.25vh;
    border-radius: 50%;
    /* Make buttons round */
    font-size: 2.5vh;
    /* Adjust icon size */
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-tap-highlight-color: transparent
}

.mobile-rotate-gallery-button {
    display: block;
    margin: 0 auto;
    transform: rotate(180deg) translateY(50%);
    transform-origin: center;
}

.mobile-gallery-button-outer-shell:active {
    background: radial-gradient(circle, #000000 0%, #ffffff00 80%);
    transition: all 0.2s ease-in-out;
}

/* Left button */
.mobile-gallery-button-outer-shell:first-of-type {
    left: calc(5vw);
    /* Position the left button with gap */
}

/* Right button */
.mobile-gallery-button-outer-shell:last-of-type {
    right: calc(5vw);
    /* Position the right button with gap */
}