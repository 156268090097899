.image-loader-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  position: absolute;
  width: 5vh;
  height: 5vh;
  border: 5px solid rgb(0, 0, 0);
  border-top: 5px solid #00fff2;
  border-radius: 50%;
  animation: spin 0.75s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
