.mobile-instructions {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    transition: opacity 0.3s ease;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin-bottom: 0;
}

.mobile-instructions-content {
    width: 100%;
    display: grid;
    grid-template-rows: 25vh auto;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
    /* Ensuring the container fills the viewport */
}

.mobile-instructions-text-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000000;
}

.mobile-instructions-text-logo-img {
    width: auto;
    height: 7vh;
}

.mobile-instructions-top-container {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* The middle row with 1fr will take the remaining space */
    grid-gap: 0px;
    height: 100%;
}

.mobile-instructions-page-class-image-container {
    height: 18vh;
    width: 18vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.25vw solid black;
    border-radius: 1vh;
    padding: 1vh;
    background-color: #fff;
    box-shadow: 0.01vh 0.01vh #000000,
        0.25vh 0.25vh 0.75vh #000000;
}

.mobile-instructions-page-class-image-container img {
    height: 18vh;
    width: auto;
    border-radius: 1vh;
    background-color: #fff;
}

.mobile-instructions-top-right-container {
    height: 25vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #000000 0%, #000000 40%, #ffffff 40%, #ffffff 100%);
}

.mobile-instructions-top-row {
    height: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
}

.mobile-instructions-top-section-deco {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-start;
    align-items: end;
}

.mobile-instructions-top-section-deco-opposite {
    bottom: 0;
    position: relative;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: end;
}

.mobile-instructions-deco-img {
    width: auto;
    height: 10vh;
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
        background: linear-gradient(to top left, #ffffffe3 0%, #ffb70000 100%);
}

.mobile-instructions-deco-img-opposite {
    width: auto;
    height: 10vh;
    transform: scaleX(-1);
    box-shadow: 0.005vw 0.005vw #000000,
        0.4vw 0.4vw 0.625vw #000000;
        background: linear-gradient(to top left, #ffffffe3 0%, #ffb70000 100%);
}

.mobile-instructions-middle-container {
    background-color: var(--competition-bottom-colour);
}

.mobile-instructions-competition-title {
    font-size: 2.5vh;
    border-radius: 0.5vw;
    font-weight: 800;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    align-self: center;
    text-align: center;
    height: 15vh;
    color: #000000;
}

.mobile-instructions-bottom-container {
    display: grid;
    grid-template-rows: 7.5vh auto;
    overflow-y: auto;
    background-color: #f2f2f2;
    border-top: 0.2vh solid black;
}

.mobile-instructions-title-text {
    font-size: 2.5vh;
    border-radius: 0.5vw;
    font-weight: 800;
    display: flex;
    justify-self: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background-color: #2e2e2e;
    color: #ffffff;
    border-top: 0.25vh solid black;
}

.mobile-instructions-item {
    display: grid;
    align-items: center;
    justify-content: left;
    padding: 1vw;
    grid-template-columns: 15% 85%;
    margin: 1vh;
    padding: 1vh;
    padding-bottom: 3vh;
    margin-top: 1vh;
    border-bottom: 0.15vh dashed black;
}

.mobile-competition-syllabus-number-circle-instructions {
    display: flex;
    align-self: flex-start;
    height: 4vh;
    width: 4vh;
    margin-left: 0.5vh;
    border-radius: 4vh;
    background-color: #000000;
    color: #ffffff;
}

.mobile-instruction-text {
    padding-left: 1vw;
    font-size: 2.25vh;
    font-weight: 400;
}
